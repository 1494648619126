import React from "react";
import styled from "@emotion/styled";
import {Overlay} from "@react-md/overlay";
import {Card, CardContent, CardHeader, CardSubtitle, CardTitle} from "@react-md/card";
import {useToggle} from "@react-md/utils";
import {Button} from "@react-md/button";
import {FontIcon} from "@react-md/icon";
import {Text} from "@react-md/typography";

const StyledOverlay = styled(Overlay)`
    align-items: center;
    display: flex;
    justify-content: center;
`;

const StyledCard = styled(Card)`
    display: block;
    max-width: 75%;
`;

const ButtonModalComponent = ({ariaLabel, id, cardTitle, cardSubtitle, cardText, positionLeft, positionTop } : ButtonModalProps) => {
    const [toggled, , disable, toggle] = useToggle(false);
    const buttonId = "icon-button-" + {id}


    const StyledButtonDiv = styled.div`
    position: absolute;
    top: ${positionTop}%;
    left: ${positionLeft}%;
    opacity: 0.8;
`;

    return (
        <>
            <StyledButtonDiv>
                <Button
                    id = {buttonId}
                    buttonType="icon"
                    theme="primary"
                    themeType="contained"
                    aria-label={ariaLabel}
                    onClick={toggle}
                >
                    <FontIcon>add</FontIcon>
                </Button>
            </StyledButtonDiv>
            <StyledOverlay id="simple-overlay" visible={toggled} onRequestClose={disable}>
                <StyledCard>
                    <CardHeader>
                        <CardTitle>{cardTitle}</CardTitle>
                        <CardSubtitle>{cardSubtitle}</CardSubtitle>
                    </CardHeader>
                    <CardContent>
                        <Text>
                            {cardText}
                        </Text>
                    </CardContent>
                </StyledCard>
            </StyledOverlay>
        </>
    );
}

type ButtonModalProps = {
    key: number,
    id: number,
    cardTitle: string,
    cardSubtitle?: string,
    cardText: string
    ariaLabel?: string,
    positionTop: number,
    positionLeft: number
}
export default ButtonModalComponent;