const data = [
  {
    id: 1,
    cardTitle: "Äussere Einflüsse",
    cardSubtitle: "Wir sind auf alles vorbereitet!",
    cardText: "Die Wolke und die Sonne stehen für das Wetter, welches grossen Einfluss auf das MTF24 haben wird, wir aber nicht beeinflussen können. Deshalb müssen wir gut auf verschiedene Situationen vorbereitet sein. \n" +
        "Der Paragraph und das Virus stehen für Gefahren und Vorschriften, welche wir nicht beeinflussen können, uns aber danach richten und diese einhalten.",
    ariaLabel: "Mehr Info",
    positionLeft: 18,
    positionTop: 15
  },
  {
    id: 2,
    cardTitle: "Finanzen",
    cardSubtitle: "Alle Kosten sind gedeckt!",
    cardText: "Das gute Erlebnis der Teilnehmenden und Besucher*innen steht im Vordergrund. Unsere Hauptmotivation ist kein wirtschaftliches Ziel. Am Ende der Reise soll eine ausgeglichene Rechnung präsentiert werden. Sämtliche Kosten von unseren Partnern sind gedeckt und die Helfer*innen entsprechend belohnt.",
    ariaLabel: "Mehr Info",
    positionLeft: 3,
    positionTop: 49
  },
  {
    id: 3,
    cardTitle: "Verkehr",
    cardSubtitle: "Wir reisen einfach und umweltbewusst!",
    cardText: "Wir fördern den öffentlichen und langsamen Verkehr. Wir reduzieren den Individualverkehr, soweit es möglich ist. ",
    ariaLabel: "Mehr Info",
    positionLeft: 16,
    positionTop: 86
  },
  {
    id: 4,
    cardTitle: "Helfer*innen",
    cardSubtitle: "Wir fördern und schützen alle Helfer*innen!",
    cardText: "Unsere Helfer*innen und Funktionär*innen sind das wertvollste Gut. Wir schützen und unterstützen sie, wo wir können.",
    ariaLabel: "Mehr Info",
    positionLeft: 20,
    positionTop: 66
  },
  {
    id: 5,
    cardTitle: "Wettkampfanlagen",
    cardSubtitle: "Wir leben turnen!",
    cardText: "Wir sorgen für gute und faire Wettkampfanlagen in allen Disziplinen. Sie sollen nah beieinander liegen, damit eine gegenseitige Unterstützung der Vereine möglich wird.",
    ariaLabel: "Mehr Info",
    positionLeft: 28,
    positionTop: 55
  },
  {
    id: 6,
    cardTitle: "Einschränkungen",
    cardSubtitle: "Wir schaffen Lösungen!",
    cardText: "Wir sind uns den Einschränkungen von unserem Wettkampfgelände und den gegebenen Voraussetzungen bewusst und finden gute und innovative Lösungen. Es gibt keine Probleme, sondern nur Herausforderungen.",
    ariaLabel: "Mehr Info",
    positionLeft: 16,
    positionTop: 49
  },
  {
    id: 7,
    cardTitle: "Infrastruktur",
    cardSubtitle: "Wenn wir etwas machen, dann richtig!",
    cardText: "Wir sorgen für eine gute und funktionierende Infrastruktur, damit sich die Teilnehmenden und Gäste auf die Wettkämpfe und das Fest konzentrieren können. Wir setzen in allen Bereichen auf gute Qualität nach dem Motto: «Wenn, dann richtig».",
    ariaLabel: "Mehr Info",
    positionLeft: 22,
    positionTop: 36
  },
  {
    id: 8,
    cardTitle: "#Härzbluet",
    cardSubtitle: "Wir geben vollen Einsatz!",
    cardText: "Die Organisation des MTF24 ist für uns eine Herzensangelegenheit. Wir bieten eine Plattform, wo die Turner*innen ihre Leidenschaft voll ausleben können. #Härzbluet",
    ariaLabel: "Mehr Info",
    positionLeft: 44,
    positionTop: 32
  },
  {
    id: 9,
    cardTitle: "Belpmoos",
    cardSubtitle: "Wir lieben das Belpmoos!",
    cardText: "Wir nutzen die Bekanntheit des Belpmoos und integrieren dessen Ausstrahlung in unsere Handlungen. Anstelle von «spick mi furt vo hie», wollen wir viele Personen anziehen - «bhalt mi hie»",
    ariaLabel: "Mehr Info",
    positionLeft: 41,
    positionTop: 21
  },
  {
    id: 10,
    cardTitle: "Fest",
    cardSubtitle: "Wir lieben das Fest!",
    cardText: "Wir legen den Fokus auf ein gutes und friedliches Fest, welche den Teilnehmer*innen lange in positiver Erinnerung bleibt.\n" +
        "Das MTF24 ist bodenständig. Wir wollen kein «höher, weiter, schneller» – Fest. Der Kern des MTF24 bildet der Wettkampf und das Fest.\n",
    ariaLabel: "Mehr Info",
    positionLeft: 48,
    positionTop: 40
  },
  {
    id: 11,
    cardTitle: "Nachhaltigkeit",
    cardSubtitle: "Wir leben Nachhaltigkeit!",
    cardText: "Wir setzen auf Nachhaltigkeit. Das ganze MTF24 ist nachhaltig und durchdacht.",
    ariaLabel: "Mehr Info",
    positionLeft: 49,
    positionTop: 59
  },
  {
    id: 12,
    cardTitle: "Gesellschaft",
    cardSubtitle: "Wir wollen ein Anlass für alle!",
    cardText: "Unsere Teilnehmenden und Gäste sind stets zufrieden und geniessen die gemütliche Gesellschaft. Mithilfe von verschiedenen Themenplattformen erreichen wir verschiedene Anspruchsgruppen.",
    ariaLabel: "Mehr Info",
    positionLeft: 59,
    positionTop: 67
  },
  {
    id: 13,
    cardTitle: "Generationenübergreifend",
    cardSubtitle: "Wir machen einen Anlass für alle!",
    cardText: "Wir bieten etwas für Jung und Alt. Entsprechend organisieren wir auch ein Wettkampf für die Jugend, Aktiven und Senioren.\n" +
        "Unser Angebot richtet sich mit verschiedenen Themenplattformen an die Bedürfnisse verschiedener Altersklassen. Wir verbinden Generationen.",
    ariaLabel: "Mehr Info",
    positionLeft: 64,
    positionTop: 80
  },
  {
    id: 14,
    cardTitle: "Datum",
    cardSubtitle: "Wir geben alles!",
    cardText: "Im Zentrum von unserem Interessen stehen die zwei Wochenende im Juni am 15./16. und 21.-23. Juni 2024. Auf dieses Datum hin richten wir alle unsere Anstrengungen aus.",
    ariaLabel: "Mehr Info",
    positionLeft: 43,
    positionTop: 90
  },
  {
    id: 15,
    cardTitle: "An-/Abreise",
    cardSubtitle: "Wir bieten Komfort!",
    cardText: "Die An-/Abreise ist einfach und die Wege zum und vom MTF24 sind kurz und klar.",
    ariaLabel: "Mehr Info",
    positionLeft: 87,
    positionTop: 83
  },
  {
    id: 16,
    cardTitle: "Erinnerungen",
    cardSubtitle: "Wir schaffen Erinnerungen!",
    cardText: "Unsere Gäste verlassen uns mit guten Erinnerungen und positiven Erlebnissen.",
    ariaLabel: "Mehr Info",
    positionLeft: 86.5,
    positionTop: 68
  },
  {
    id: 17,
    cardTitle: "Luftsprünge",
    cardSubtitle: "Wir ermöglichen Luftsprünge!",
    cardText: "Wir ermöglichen Luftsprünge – Gute, hohe und schöne Sprünge in allen Disziplinen dank der guten Anlagen und Luftsprünge beim gesellschaftlichen Teil, weil es einfach «fägt».",
    ariaLabel: "Mehr Info",
    positionLeft: 69,
    positionTop: 58
  },
  {
    id: 18,
    cardTitle: "Informatioen",
    cardSubtitle: "Wir kommunizieren!",
    cardText: "Wir informieren und orientieren die Teilnehmenden und Gästen jederzeit optimal. Unsere Kommunikation ist stets sachlich und zielführend.",
    ariaLabel: "Mehr Info",
    positionLeft: 63,
    positionTop: 36
  },
  {
    id: 19,
    cardTitle: "Belp",
    cardSubtitle: "Wir sind Belp!",
    cardText: "Belp als Dorf ist ein wichtiger Bestandteil von unserem Anlass. Wir fördern und fordern das lokale Gewerbe und streben für beide Seiten gewinnbringende Lösungen an.\n" +
        "Wir organisieren nicht nur ein Turnfest, sondern auch ein Anlass für die Gemeinschaft. Wir kommunizieren aktiv mit der Bevölkerung und binden diese in unsere Planung ein, weil «Belp läbt – Belp fägt.»\n",
    ariaLabel: "Mehr Info",
    positionLeft: 88,
    positionTop: 43
  }
  ,
  {
    id: 20,
    cardTitle: "Netzwerk",
    cardSubtitle: "Wir nutzen unsere Kontakte!",
    cardText: "Wir nutzen unser grosses Netzwerk und setzen diese Kontakte zu Gunsten des MTF24 ein. Wir haben eine gute Zusammenarbeit mit den Behörden. Wir verschmelzen Neues mit Bekanntem.",
    ariaLabel: "Mehr Info",
    positionLeft: 75,
    positionTop: 22
  }
]
export type strategieDataProps = {
  id: number,
  cardTitle: string,
  cardSubtitle?: string,
  cardText: string
  ariaLabel?: string,
  positionLeft: number,
  positionTop: number
}

export default data;
