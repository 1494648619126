import React from "react";
import styled from "@emotion/styled";
import Background from './assets/Strategie_MTF24.jpg';
import ButtonModalComponent from "./components/ButtonModalComponent";
import data, {strategieDataProps} from "./data/strategieData";

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledContainer = styled.div`
    position: relative;
`;

const StyledBackgroundImage = styled.img`
     max-width: 100%;
     max-height: 100vh;
`;

const App = () => {


    return (
        <>
            <Container>
            <StyledContainer>
                <StyledBackgroundImage src={Background} alt={"Strategiebild MTF 24 Belp"}/>
                {data.map((item: strategieDataProps, key) =>

                    <ButtonModalComponent
                        key={key}
                        id={item.id}
                        cardTitle={item.cardTitle}
                        cardSubtitle={item.cardSubtitle}
                        cardText={item.cardText}
                        ariaLabel={item.ariaLabel}
                        positionLeft={item.positionLeft}
                        positionTop={item.positionTop}
                    />
                )}
            </StyledContainer>
            </Container>
        </>

    );
}

export default App;
